/* Media Queries for Responsiveness */

@media (max-width: 576px) {
    .header-navbar-section li {
        padding-left: 30px;
    }

    .header-section {
        padding: 15px;
    }

    .horizontal-card {
        margin-bottom: 15px;
    }

    .homepage-about-img {
        padding: 15px;
    }

    .f-grid {
        grid-template-columns: 1fr;
    }

    .header-section {
        padding: 15px !important;
    }

    .horizontal-card {
        padding: 0;
    }

    .card-content h3 {
        padding: 0 15px;
        font-size: 20px;
        line-height: 25px;
        margin-top: -30px;
    }

    .f-grid {
        grid-template-columns: 1fr !important;
        grid-row-gap: 25px;
    }

    .rows-container {
        padding: 15px !important;
    }

    .header-right {
        width: 100%;
    }

    .service-first-section {
        padding: 15px !important;
    }

    .service-second-section {
        flex-direction: column !important;
        padding: 15px !important;
    }

    .service-right img {
      
        width: 91%;
        height: 335px;
        padding-left: 20px;
    }
    .service-left {
        width: 100% !important;
    }

    .service-right {
        width: 100% !important;
    }

    .service-right100 {
        padding-right: 0px !important;
    }

    .service-left100 {
        padding-left: 0px !important;
    }

    .contact-header {
        padding: 15px;
    }

    .contact-info-section {
        padding: 5px;
    }

    .contact-left {
        width: 100%;
        margin-top: 10px;
    }
    .contact-content {
        padding-left: 0px;
    }
    .contact-right {
        width: 100%;
    }

    .contact-right {
        width: 100%;
        padding-left: 0px !important;
    }

    .contact-fName-lName {
        flex-direction: column !important;
    }

    .fName-input {
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    .lname-input {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 30px !important;
    }

    .contact-btm-section {
        flex-direction: column !important;
        padding: 15px !important;
    }

    .contact-btm-left {
        width: 100% !important;
    }

    .contact-btm-right {
        width: 100% !important;
        padding-left: 0 !important;
    }
    .about-page .header-left {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .about-page .header-right {
        padding-right: 0 !important;
    }
    .contact-grid {
        gap: 0px;
        padding-left: 0px;
    }
    .contact-info {
        padding: 15px 15px 15px 0px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        gap: 15px;
        padding: 0px;
    }
    .service-h1 {
        padding-bottom: 20px;
    }
    .footer {
        padding: 15px !important; 
    }
    .logo-letter {
        font-size: medium;
    }
    .horizontal-card {
        height: 35vh;
    }
    .feedback-form {
        padding-left: 0px !important;
    }
    .submit-button.w-button {
        margin-left: 0px !important;
    }
    .about-header-img img {
        border-radius: 999px;
        width: 250px;
        height: 150px;
        object-fit: cover;
    }
    .pdng-rt30 {
        padding-right: 0px;
      }
}

@media (max-width: 768px) {
    .header-section {
        display: flex;
        padding: 30px;
        flex-direction: column;
    }

    .header-left {
        width: 100%;
    }

    .arrow-btn-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }

    .header-right-img {
        width: 100%;
    }

    .card-image img {
        max-width: 150px;
    }

    .section-third-content {
        padding: 30px 30px 0px 30px;

    }

    .learn-more-btn {
        transform: translate(-50%, -90%) scale(2);
    }
    .service-right img {
      
        width: 91%;
        height: 335px;
        padding-left: 20px;
    }
    .about-arrow-btn {
        padding: 0;
    }

    .footer {
        padding: 30px;
    }

    .f-grid {
        grid-template-columns: 1fr 1fr;
    }

    .section-third-content-btm {
        padding: 0 30px;
    }

    .horizontal-card {
        width: 90%;
        max-width: 100%;
        /* margin-bottom: 15px; */
    }

    .learn-more-btn {
        padding: 5px 10px;
        margin-left: 50px;
    }

    .rows-container {
        padding: 30px;
    }

    .header-right {
        width: 100%;
    }

    .service-right100 {
        padding-right: 0px;
    }

    .service-left100 {
        padding-left: 0px;
    }

    .service-second-section {
        padding: 30px;
        flex-direction: column;
    }

    .service-left {
        width: 100%;
    }

    .service-right {
        width: 100%;
    }

    .contact-btm-section {
        flex-direction: column;
        padding: 15px;
    }

    .contact-btm-left {
        width: 100%;
    }

    .contact-btm-right {
        width: 100%;
        padding-left: 0;
    }

    .contact-header {
        padding: 15px;
    }

    .contact-info-section {
        padding: 5px;
    }

    .contact-left {
        width: 100%;
        margin-top: 10px;
    }
    .contact-content {
        padding-left: 0px;
    }
    .contact-right {
        width: 100%;
    }

    .contact-right {
        width: 100%;
        padding-left: 30px;
    }

    .contact-fName-lName {
        flex-direction: column !important;
    }

    .fName-input {
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    .lname-input {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 30px !important;
    }

    .contact-btm-section {
        flex-direction: column !important;
        padding: 15px !important;
    }

    .contact-btm-left {
        width: 100% !important;
    }

    .contact-btm-right {
        width: 100% !important;
        padding-left: 0 !important;
    }
    .feedback-form {
        padding-left: 30px;
    }
    .contact-btm-section .w-button {
        margin-left: 30px;
    }

}

@media (max-width: 992px) {

    .about-page .header-left {
        padding-right: 30px;
    }

    .about-page .header-right {
        padding-right: 30px;
    }

    .service-second-section {
        padding: 30px;
        flex-direction: row;
    }

    .service-right100 {
        padding-right: 100px;
    }

    .service-left100 {
        padding-left: 100px;
    }

    .contact-fName-lName {
        flex-direction: row;
    }

    .fName-input {
        width: 45%;
        margin-bottom: 30px;
    }

    .lname-input {
        width: 45%;
        margin-left: 5%;
        margin-bottom: 30px;
    }

    .contact-btm-section {
        flex-direction: row;
        padding: 15px;
    }

    .contact-btm-left {
        width: 40%;
    }

    .contact-btm-right {
        width: 60%;
        padding-left: 0;
    }
    .contact-btm-section {
        padding-top: 50px;
    }
    .service-right img {
      
        width: 91%;
        height: 335px;
        padding-left: 20px;
    }
}


@media (max-width: 1200px) {}


@media (max-width: 1400px) {}



/* Styles for larger screens */
@media (min-width: 1025px) {}

