
/* Basic styling for the header */
.header-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header-logo img {
    width: 180px;
  }
  
  /* Hamburger icon styling */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 4px;
  }
  
  .hamburger span {
    height: 3px;
    width: 25px;
    background-color: #333;
    transition: 0.3s;
  }
  
  /* Styling for when the hamburger is open */
  .hamburger.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* Navbar section styling */
  .header-navbar-section {
    display: flex;
    gap: 20px;
    list-style-type: none;
  }
  
  /* Links inside the navbar */
  .header-navbar-section a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #333;
    padding: 0.5rem 1rem;
  }
  
  .header-navbar-section a:hover {
    color: #333;
  }
  
  /* Mobile first: Hide the navbar by default and only show the hamburger */
  @media (max-width: 768px) {
    .header-navbar-section {
      position: absolute;
      top: 127px;
      left: 0;
      right: 0;
      background-color: white;
      flex-direction: column;
      align-items: center;
      display: none;
    }
  
    .header-navbar-section.open {
      display: flex;
    }
  
    .header-navbar-section li {
      width: 100%;
      text-align: center;
      /* padding: 1rem 0; */
    }
  
    /* Show the hamburger on mobile devices */
    .hamburger {
      display: flex;
    }
  }
  
  /* Larger screens: display the navbar normally */
  @media (min-width: 768px) {
    .header-navbar-section {
      /* display: flex; */
      position: static;
    }
  
    .hamburger {
      display: none;
    }
  }
  

  /* Active link styling */
.header-navbar-section a.active {
  position: relative;
  color: #ff6a00; /* Change text color */
}

.header-navbar-section a.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #ff6a00; /* Orange line */
  border-radius: 2px;
}

.header-tag-line {
  
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #ff6a00; /* Orange line */
  border-radius: 0.5px;
}

