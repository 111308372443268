/* General CSS Start  */

section {
    overflow: hidden;
}

form {
    display: contents;
}

.container {
    padding: 0 80px;
}

.primary-color {
    color: #635f5f;
}

.text-center {
    text-align: center;
}

.maxW100 {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: #635f5f;
}

li {
    list-style: none;
}

/* Base styling for headings */
h1 {
    letter-spacing: -0.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 42px; /* Mobile-first size */
    font-weight: 500;
    line-height: 50px;
}

h2 {
    letter-spacing: -0.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 34px; /* Mobile-first size */
    font-weight: 500;
    line-height: 40px;
}

h3 {
    letter-spacing: -0.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px; /* Mobile-first size */
    font-weight: 500;
    line-height: 32px;
}

.h4-subtext {
    font-family: Overusedgrotesk, sans-serif;
    font-size: 20px; /* Mobile-first size */
    font-weight: 400;
    line-height: 28px;
}

/* Tablet screens (min-width: 768px) */
@media (min-width: 768px) {
    h1 {
        font-size: 48px;
        line-height: 58px;
    }

    h2 {
        font-size: 38px;
        line-height: 44px;
    }

    h3 {
        font-size: 28px;
        line-height: 34px;
    }

    .h4-subtext {
        font-size: 22px;
        line-height: 30px;
    }
}

/* Laptop and larger screens (min-width: 1024px) */
@media (min-width: 1024px) {
    h1 {
        font-size: 55px;
        line-height: 66px;
    }

    h2 {
        font-size: 42px;
        line-height: 48px;
    }

    h3 {
        font-size: 30px;
        line-height: 36px;
    }

    .h4-subtext {
        font-size: 24px;
        line-height: 32px;
        /* padding: 10px; */
    }
}

/* General CSS End  */

/* Header css start  */


.footer-section {
    position: relative;
    bottom: 0;
}


.header-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-right {
    width: 50%;
}

.arrow-btn {
    background-color: #efefef;
    width: 100px;
    padding: 12px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
}

.arrow-btn-section {
    display: flex;
    align-items: center;
}

.right-arrow {
    background-color: #ed5818;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-section {
    display: flex;
    padding: 50px 0 95px 80px;
    align-items: center;
}

.header-right img {
    max-width: 100%;
}

/* Header css end  */
.section-third {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.section-third-content {
    max-width: 790px;
    text-align: center;
}

.about-arrow-btn {
    padding: 50px;
}

/* footer css start  */
.footer-logo-wrap img {
    max-width: 150px;
}

.charcoal-50 {
    background-color: #333;
    height: 50%;
    position: absolute;
    inset: auto 0% 0%;
}

.charcoal-50.top {
    inset: 0% 0% auto;
}

.footer {
    background-color: #333;
    flex-direction: column;
    align-items: center;
    padding: 120px 80px 75px;
    display: flex;
}

.footer-wrap {
    grid-column-gap: 110px;
    grid-row-gap: 110px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    display: flex;
}

.f-grid {
    grid-column-gap: 90px;
    grid-row-gap: 110px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-self: stretch;
    display: grid;
}

.footer-links {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.footer-link {
    opacity: 0.7;
    color: #faf8f8;
    font-size: 14px;
    text-decoration: none;
    transition: opacity 0.3s;
}

.footer-link:hover {
    opacity: 1;
    color: #faf8f8;
}

.footer-link.social {
    text-decoration: underline;
}

.footer-link.social.dark {
    color: #333;
}

.footer-link.no-link {
    transition-property: none;
}

.footer-link.no-link:hover {
    opacity: 0.7;
}

.footer-logo-wrap {
    flex-direction: row;
    align-items: flex-end;
    display: flex;
}

.site-details {
    opacity: 0.7;
    color: #faf8f8;
    align-items: flex-end;
    font-size: 10px;
    line-height: 15px;
    display: flex;
}

.footer-logo {
    width: 187px;
}

.subtitle-footer {
    color: #ed5818;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

/* footer css end  */

/* Home page css start  */
.section-third-content-btm {
    max-width: 790px;
    text-align: center;
}

.homepage-about-img img {
    border-radius: 999px;
    width: 800px;
    height: 475px;
    object-fit: cover;
}

.header-right-img {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    width: 45vw;
    height: 72.5vh;
    overflow: hidden;
}

.header-right.header-right-img img {
    object-fit: cover;
    width: 100%;
    height: 110%;
}

/* Home page css start  */

/* service page css start  */

.service-img img {
    border-radius: 999px;
    width: 800px;
    height: 475px;
    object-fit: cover;
}

.service-first-section {
    max-width: 1025px;
    padding: 50px 80px 0px;
    text-align: center;
    margin: auto;
}

.service-h1 {
    padding-bottom: 55px;
}

.service-second-section {
    padding: 50px 80px 50px;
    display: flex;
    align-items: center;
}

.service-left100 {
    padding-left: 100px;
}

.service-right100 {
    padding-right: 100px;
}

.service-left {
    width: 90%;
}

.service-right {
    width: 50%;
}

.service-right img {
    /* border-radius: 999px; */
    width: 100%;
    height: 383px;
    /* width: 100%;
    height: 500px; */
}

.section-third-section {
    flex-direction: column;
    align-items: center;
    padding: 0 80px 200px;
    display: flex;
}

.service-fourth-section {
    align-items: center;
    padding: 100px 80px;
    display: flex;
}

.service-sub-img {
    padding-right: 50px;
}

.service-sub-img img {
    border-radius: 999px;
}

.service-testimonial {
    background-color: #333;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 80px;
    display: flex;
    margin-top: 100px;
}

.service-testimonial-txt {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    max-width: 675px;
    padding: 40px 0;
}

.service-testimonial-name {
    color: #ed5818;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.service-qa-section {
    padding: 100px 80px;
}

.service-qa-wrapper {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;
    align-items: flex-start;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.service-faq-accordion {
    border-bottom: 1px solid #efefef;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 8px;
    padding-bottom: 8px;
}

.service-faq-question {
    cursor: pointer;
    border-top: 1px solid #0000;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-weight: 600;
    display: flex;
}

.service-faq-icon-wrapper {
    padding-left: 100px;
}

/* service page css end  */

/* About page css start  */

.about-page .header-left {
    padding-right: 140px;
}

.about-page .header-right {
    padding-right: 140px;
}

.about-third-section {
    padding: 0 80px;
}

.about-column {
    display: flex;
    padding: 80px 0;
}

.about-third-section .about-left {
    width: 50%;
    padding-left: 100px;
}

.about-third-section .about-right {
    width: 50%;
}

.about-page .subtitle {
    color: #ed5818;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.about-divider {
    background-color: #efefef;
    width: 100%;
    height: 1px;
}

.about-video-section {
    background-color: #efefef;
    padding-top: 150px;
    padding-left: 80px;
    padding-right: 80px;
}

.about-fourth-section {
    background-color: #efefef;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 80px;
    display: flex;
}

.about-fourth-section-content {
    max-width: 700px;
}

.about-logo {
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
}

.about-header-img img {
    border-radius: 999px;
    width: 600px;
    height: 400px;
    object-fit: cover;
}

/* About page css end  */

/* Contact css start  */

.contact-header {
    padding: 50px 60px 0px;
    text-align: center;
}

.contact-header-img {
    /* max-width: 100%; */
    text-align: center;
}

.contact-info {
    padding: 100px 80px 150px;
    display: flex;
}

.contact-left {
    width: 50%;
    margin-top: -150px
}

.contact-right {
    width: 50%;
}

.conatct-social-link {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.field {
    border-style: solid;
    border-width: 1px;
    border-color: #fd8a23;
    color: #333;
    letter-spacing: -0.02em;
    background-color: #fff0;
    border-radius: 8px;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    transition: border-color 0.3s;
    width: 90%;
}

.field:active,
.field:focus {
    border-bottom-color: #ed5818;
}

.field::placeholder {
    color: #3336;
}

.field:focus-visible {
    outline: none;
}

.field.area {
    height: 185px;
}

.notrobot-input {
    border: 1px solid #d3d3d3;
    background: #f9f9f9;
    color: #000;
    height: 85px;
    width: 300px;
    padding: 10px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
}

.circleIcon {
    width: 48px;
    height: 48px;
}

.submit-button {
    color: #fff;
    letter-spacing: -0.02em;
    background-color: #ed5818;
    margin-top: -20px;
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    transition: color 0.3s;
    border: none;
    border-radius: 3px;
}

/* contact info component css  */

.conatct-contant {
    padding: 80px 80px 0px 80px;
}

.conatct-fName-lName {
    display: flex;
}

.contact-content {
    padding-left: 40px;
}

.contact-info-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 100px 20px;
    margin: 10px;
}

.contact-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

.icon-box-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-box-icon {
    margin-right: 10px;
}

.icon-box-content {
    display: flex;
    flex-direction: column;
}

.icon-box-title {
    font-size: 1.2em;
    margin: 0;
}

.icon-box-description {
    margin: 0;
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 20px;
    padding-left: 15px;
}

.fName-input {
    width: 45%;
    margin-bottom: 30px;
}

.lname-input {
    width: 45%;
    margin-left: 5%;
    margin-bottom: 30px;
}

.mail-input {
    margin-bottom: 30px;
}

.msg-input {
    margin-bottom: 50px;
}

.mail-input span {
    display: block;
}

.contact-title {
    color: #ed5818;
}

.contact-subtitle {
    color: #ed5818;
}

.icon-box-icon img {
    max-width: 50px;
}

.contact-fName-lName {
    display: flex;
}

.contact-btm-section {
    display: flex;
    align-items: center;
    padding: 0 80px;
    background-color: #333;
}

.contact-btm-left {
    width: 40%;
}

.contact-btm-left img {
    max-width: 100%;
}

.contact-btm-right {
    width: 60%;
    padding-left: 100px;
}

.contact-btm-right h1 {
    color: #fff;
}

.contact-btm-right p {
    color: #c0c0c0;
}

.contact-h-txt ul {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    margin-bottom: 50px;
}

.contact-h-txt span {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.contact-page .cta {
    display: none;
}

.contact-h-txt span {
    padding-left: 30px;
}

.contact-h-txt li {
    display: flex;
    align-items: center;
}

.people-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 100px;
}

/* Create three equal columns that float next to each other */
.people-column {
    width: 23%;
    display: none;
    /* Hide all elements by default */
    box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.4);
    margin: 15px 10px;
}

.people-page-title {
    padding: 30px 0;
}

/* Clear floats after rows */
.people-row:after {
    content: "";
    display: table;
    clear: both;
}

/* The "show" class is added to the filtered elements */
.show {
    display: block;
}

/* Style the buttons */
.btn {
    border: none;
    outline: none;
    padding: 12px 16px;
    background-color: white;
    cursor: pointer;
}

.btn:hover {
    background-color: #ddd;
}

.btn.active {
    background-color: #666;
    color: white;
}

.people-page .content img {
    max-width: 50%;
}

#myBtnContainer {
    margin: 30px 0;
}

/* Container for each image */
.content {
    position: relative;
    overflow: hidden;
}

/* Image styling */
.image {
    display: block;
    width: 100%;
    transition: transform 0.5s ease;
}

/* Hover effect for the image */
.content:hover .image {
    transform: scale(1.1);
}

/* Overlay container */
.content .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent background */
    overflow: hidden;
    height: 0;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0;
}

/* Show the overlay on hover */
.content:hover .overlay {
    height: 100%;
}

/* Overlay text styling */
.overlay h4,
.overlay h5,
.overlay p,
.overlay a {
    margin: 10px 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
    padding: 0 10px;
}

/* Text appears on hover */
.content:hover .overlay h4,
.content:hover .overlay h5,
.content:hover .overlay p,
.content:hover .overlay a {
    opacity: 1;
    transform: translateY(0);
}

.people-txt {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
}

/* Add these styles to your CSS file or inside a <style> tag in the same file */

.people-txt {
    display: block;
    /* Show the text by default */
}

.overlay {
    display: none;
    /* Hide the overlay by default */
    position: absolute;
    /* Position it absolutely within the parent */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Example background for the overlay */
    color: #fff;
    /* Text color for the overlay */
    padding: 20px;
    /* Some padding for the overlay content */
    box-sizing: border-box;
    /* Ensure padding does not increase width */
}

/* Show the overlay and hide the text on hover */
.content:hover .people-txt {
    display: none;
    /* Hide text on hover */
}

.content:hover .overlay {
    display: block;
    /* Show overlay on hover */
}

.content {
    position: relative;
    /* Relative positioning for the overlay */
}

/* Horizontal card css   */

.horizontal-card {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 400px;
    position: relative;
}

.card-image img {
    width: 150px;
    height: auto;
}

.card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.card-footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
}

.learn-more-btn {
    background-color: #ed5818;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 2px solid #fff;
}

.learn-more-btn:hover {
    background-color: #ed5818;
}

.card-layout {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .card-layout {
        flex-direction: column;
    }
}

.horizontal-card {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 65%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 16px 0;
    margin: auto;
    margin-bottom: 30px;
    position: relative;
}

.card-image img {
    width: 300px;
    height: auto;
    object-fit: cover;
    padding: 15px;
}

.card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.card-content h3 {
    margin: 0 0 8px;
    font-size: 1.5rem;
    color: #333;
}

.card-content h5 {
    margin: 0 0 8px;
    font-size: 1.2rem;
    color: #777;
}

.card-content p {
    margin: 0;
    font-size: 1rem;
    color: #555;
}

/* Loader css start   */

/* Loader container */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* Optional background */
    z-index: 1000;
    transition: all 0.5s ease;
}

/* Move loader from top to bottom */
.up-to-down {
    transform: translateY(-100%);
    /* Start off-screen (above) */
    animation: slideDown 1s forwards;
}

/* Move loader from bottom to top */
.down-to-up {
    transform: translateY(0);
    /* Start from middle */
    animation: slideUp 1s forwards;
}

/* Slide down animation */
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

/* Slide up animation */
@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

/* Loader content styling (centered image) */
.loader-content {
    width: 40px;
    height: 50px;
}

.btm-line {
    background-color: #efefef;
    width: 100%;
    height: 1px;
}

.rows-container {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* padding: 0px 80px; */
}

.row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.row-title {
    flex: 1;
    font-weight: bold;
    text-align: left;
}

.row-description {
    flex: 1.5;
    text-align: left;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 20px;
    padding: 20px;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.grid-item label {
    font-weight: bold;
}

.grid-view-section {
    margin-top: 50px;
}

.horizontal-card:hover::before {
    border-radius: 0;
    height: 100%;
    /* width: 100%; */
}

.horizontal-card::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    background-color: #635f5f;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    transition: 0.9s ease;
    border-radius: 50% 50% 0px 0px;
}

.footer-links a {
    opacity: 0.7;
    color: #faf8f8;
    font-size: 14px;
    text-decoration: none;
    transition: opacity 0.3s;
}

.input-error {
    border-color: #ed5818;
}

.error-message {
    color: #ed5818;
    font-size: 12px;
    margin-top: 5px;
}

/* Override the background color of success toast */
.toast-success {
    background-color: #28a745;
    /* Green background for success */
    color: #fff;
    /* White text color */
}

/* Override the background color of error toast */
.toast-error {
    background-color: #dc3545;
    /* Red background for error */
    color: #fff;
    /* White text color */
}

/* Container for the logo */
.logo-container {
    display: inline-flex;
    justify-content: right;
    align-items: center;
    font-size: 48px;
    /* Adjust as needed */
    font-weight: bold;
    color: #333;
    /* Logo color */
    /* margin-right: 200px;  Moves the element 10px to the left */

    width: 100%;
}

/* Individual letters with animation */
.logo-letter {
    display: inline-block;
    opacity: 0;
    margin-right: 5px;
    
    transform: translateY(-50px);
    /* Start position (before dropping) */
    animation: dropIn 0.3s forwards;
    /* Trigger drop animation */
    color: #ed5818;
}

/* Keyframes for dropping animation */
@keyframes dropIn {
    to {
        opacity: 1;
        transform: translateY(0);
        /* End position (after dropping) */
    }
}

/* Loader container (full screen with centered content) */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    /* Background color of the loader screen */
    z-index: 9999;
    /* Ensure it stays on top of other content */
    transition: all 0.5s ease;
    /* Smooth transition for animation */
}

.up-to-down {
    transform: translateY(0);
    /* Initial position (starts from the top) */
    opacity: 1;
    /* Visible initially */
}

.down-to-up {
    transform: translateY(-100vh);
    /* Moves out of the view from the top */
    opacity: 0;
    /* Fades out */
}

.loader-content img {
    width: 50px;
    height: auto;
}

/* Ensure the main content takes full viewport height */
.main-content {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    /* Background color for main content */
}

.horizontal-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    width: 60%;
    position: relative;
}

.card-image img {
    max-width: 250px;
    /* height: 80px; */
    object-fit: cover;
    margin-right: 10px;
}

.card-content {
    flex: 1;
}

.card-footer {
    position: relative;
}

.learn-more-btn {
    display: none;
    /* Initially hidden */
    padding: 8px 12px;
    font-size: 10px;
    background-color: #ed5818;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    left: 32%;
    top: 50%;
    transform: translate(-50%, 200%) scale(2);
    /* Center and enlarge */
    z-index: 1;
}

.horizontal-card:hover .learn-more-btn {
    display: block;
    /* Show on hover */
}

.feedback-form input,
.feedback-form textarea {
    background-color: #fff;
}

.header-navbar-section li a {
    color: #ed5818;
}

.logo-letter {
    color: #fff;
}

/* .loader-container { */
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; */
    
    /* Applying background with four layers using gradients */
    /* background:  */
      /* linear-gradient(to top right, rgba(0, 0, 0, 0.85) 50%, transparent 50%), Dark top right */
      /* linear-gradient(to bottom right, #f57c00 40%, transparent 60%),          Orange bottom right */
      /* linear-gradient(to top left, #ffb300 50%, transparent 50%),              Yellow top left */
      /* linear-gradient(to bottom left, #ff8c00 50%, transparent 50%);           Orange bottom left */

     
  /* } */
  .banner-img {
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
}
  
  .loader-content {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    color: white;
  }
  
  .up-to-down {
    animation: slideDown 1.5s forwards;
  }
  
  .down-to-up {
    animation: slideUp 1.5s forwards;
  }

  .submit-button.disabled {
  cursor: not-allowed;   
  opacity: 0.6;         
}
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  .loader-container {
    background-image: url(../images/banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.loader-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    color: white !important;
  }

  .loader-content-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }



  





























































































