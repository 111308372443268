/* Basic styles for the horizontal card */
.horizontal-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background-color: white;
    margin: 1rem;
  }
  
  .horizontal-card:hover {
    transform: scale(1.02);
  }
  
  .card-image img {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 1rem;
  }
  
  .card-content h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .card-footer {
    margin-top: 1rem;
  }
  
  .learn-more-btn {
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-btn:hover {
    background-color: #0056b3;
  }
  
  /* Mobile-first styles */
  @media (max-width: 768px) {
    .horizontal-card {
      flex-direction: column;
    }
  
    .card-image img {
      width: 100%;
    }
    
  }
  
  /* Tablet and larger screens */
  @media (min-width: 768px) {
    .horizontal-card {
      flex-direction: row;
      align-items: center;
    }
  
    /* .card-image { */
      /* flex: 1; */
    /* } */
  
    .card-content {
      flex: 2;
      padding: 1.5rem;
    }
  
    .card-content h3 {
      font-size: 1.5rem;
    }
  }
  
  /* Larger screens: desktop and above */
  @media (min-width: 1024px) {
    .horizontal-card {
      max-width: 800px;
      margin: 2rem auto;
    }
  
    .card-content h3 {
      font-size: 1.75rem;
    }
  
    .learn-more-btn {
      padding: 0.75rem 1.5rem;
    }
  }
  
