.pdng-rt30 {
  padding-right: 30px;
}
.about-content-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  margin-bottom: 50px;
}
.contact-btm-left {
  text-align: center;
}

/* Container for the rows */
.rows-container {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Each row */
  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    /* background-color: #f9f9f9; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  /* Title section */
  .row-title h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  /* Description section */
  .row-description p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Bottom separator line */
  .btm-line {
    width: 100%;
    height: 2px;
    background-color: #ccc;
    margin: 1rem 0;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .row-title h2 {
      font-size: 1.25rem;
    }
  
    .row-description p {
      font-size: 0.9rem;
    }
  
    .btm-line {
      margin: 0.5rem 0;
    }
    .about-content-img {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      margin-bottom: 30px;
  }
  }
  
  /* Tablet and larger screen styles */
  @media (min-width: 768px) {
    .row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .row-title {
      flex: 1;
      padding-right: 1rem;
    }
  
    .row-description {
      flex: 2;
    }
  
    .btm-line {
      margin-top: 1.5rem;
    }
  }
  
  /* Desktop and larger screen styles */
  @media (min-width: 1024px) {
    .row-title h2 {
      font-size: 2.0rem;
    }
  
    .row-description p {
      font-size: 1.1rem;
    }
  
    .btm-line {
      margin: 2rem 0;
    }
  }
  








  